import { GROUP_LIST } from "constants/actionTypes";
import { SHOW_GROUP } from "constants/actionTypes";
import { LOAD_FORM_GROUP } from "constants/actionTypes";
import { EDIT_GROUP, NEW_GROUP, DELETE_GROUP, GROUP_PROGRAM } from "constants/actionTypes";
import { UPLOAD_IMAGE } from "constants/actionTypes";
import { GET_PROJECT_PROGRESS } from "constants/actionTypes";
import { MBS_IMAGE_ALERT, DELETE_IMAGE_ALERT, SA_FILE_ALERT } from "constants/actionTypes";
import { adjustDate } from "assets/functions/general.jsx";
import { UPLOAD_SA_FILE } from "constants/actionTypes";
import { LIST_INTRODUCTION } from "constants/actionTypes";
import { NEW_INTRODUCTION } from "constants/actionTypes";
import { SHOW_INTRODUCTION } from "constants/actionTypes";
import { EDIT_INTRODUCTION } from "constants/actionTypes";
import { LOAD_FORM_INTRODUCTION } from "constants/actionTypes";
import { UPDATE_GROUP_PAGINATION } from "constants/actionTypes";
import { LOADING_GROUP } from "constants/actionTypes";
import { PAGINATED_GROUP_LIST } from "constants/actionTypes";
import { GROUP_CUSTOMIZED_URL } from "constants/actionTypes";
import { CLEAR_GROUP_CUSTOMIZED_URL } from "constants/actionTypes";

const initialState = {
  show_group: {
    id: "",
    embassador: {
      first_name: "",
      last_name: "",
      language: "en",
    },
    name: "",
    data: [],
    first_name: "",
    last_name: "",
    start_date: "",
    final_date: "",
    number_students: "",
    madality: "",
    program: ""
  },
  new_group: {
    id: "",
    type: "state.free"
  },
  group_list: [],
  paginated_group_list: [],
  group_list_pagination: {
    keyword: "",
    page: 1,
    page_size: 10,
    order_column: "start_date",
    order_direction: "DESC",
  },
  pages_total: "1",
  search_loading: false,
  group_program: [],
  progress_list: {
    student_progress: [],
    student_old_progress: []
  },
  loading: true,
  image_alert: false,
  sa_file_alert: false,
  introduction_list: [],
  show_introduction: {
    id: "",
    content: "",
    language: "en",
    type: "",
    group: {
      name: ""
    }
  },
  new_introduction: {
    group_id: ""
  },
  group_customized_url: {
    id: "",
    language: ""
  }
}

export const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_LIST:
      return Object.assign({}, state, {
        group_list: action.payload,
        loading: false
      });

    case PAGINATED_GROUP_LIST:
      return Object.assign({}, state, {
        paginated_group_list: action.payload && action.payload.list ? action.payload.list : [],
        pages_total: action.payload.pages_total,
        loading: false
      });
    case GROUP_PROGRAM:
      return Object.assign({}, state, {
        group_program: action.payload,
        loading: false
      });
    case SHOW_GROUP:
      return Object.assign({}, state, {
        show_group: action.payload
      });
    case LOAD_FORM_GROUP:
      const group = action.data;
      if (group.number_students_graduated !== undefined) {
        group.number_students_graduated = group.number_students_graduated.toString();
      }
      else {
        group.number_students_graduated = "1";
      }
      group.start_date = adjustDate(group.start_date);
      group.final_date = adjustDate(group.final_date);
      group.graduation_date = adjustDate(group.graduation_date);

      return Object.assign({}, state, {
        data: group
      });
    case EDIT_GROUP:
      return Object.assign({}, state, {
        edit_group: action.payload
      });
    case NEW_GROUP:
      return Object.assign({}, state, {
        new_group: action.payload
      });
    case DELETE_GROUP:
      return Object.assign({}, state, {
        delete_group: action.payload
      });
    case GET_PROJECT_PROGRESS:
      return Object.assign({}, state, {
        progress_list: action.payload,
        loading: false,
      });
    case MBS_IMAGE_ALERT:
      return Object.assign({}, state, {
        id_student: action.payload,
        image_alert: true,
      });
    case UPLOAD_IMAGE:
      return Object.assign({}, state, {
        upload_image: action.payload,
      });
    case UPLOAD_SA_FILE:
      return Object.assign({}, state, {
        upload_sa_file: action.payload,
      });
    case DELETE_IMAGE_ALERT:
      return Object.assign({}, state, {
        image_alert: false,
        sa_file_alert: false,
      });
    case SA_FILE_ALERT:
      return Object.assign({}, state, {
        id_student: action.payload,
        sa_file_alert: true,
      });
    case LIST_INTRODUCTION:
      return Object.assign({}, state, {
        introduction_list: action.payload,
        loading: false,
      });
    case SHOW_INTRODUCTION:
      return Object.assign({}, state, {
        show_introduction: action.payload
      });
    case NEW_INTRODUCTION:
      return Object.assign({}, state, {
        new_introduction: action.payload
      });
    case EDIT_INTRODUCTION:
      return Object.assign({}, state, {
        edit_introduction: action.payload
      });
    case LOADING_GROUP:
      return Object.assign({}, state, {
        loading: true
      });
    case LOAD_FORM_INTRODUCTION:
      var introduction = action.data;
      return Object.assign({}, state, {
        data: introduction
      });
    case GROUP_CUSTOMIZED_URL:
      return Object.assign({}, state, {
        group_customized_url: action.payload
      });
    case CLEAR_GROUP_CUSTOMIZED_URL:
      return Object.assign({}, state, {
        group_customized_url: {
          id: "",
          language: ""
        }
      });
  }
  return state;
}
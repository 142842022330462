import { SHOW_PROGRAMMBS, UPDATE_REVISION_PROGRAMMBS, ERROR_EDIT_REVISION, SUCCESSFULL_EDIT_REVISION, SUCCESSFULL_APPROVE_PROJECT, ERROR_APPROVE_PROJECT } from "constants/actionTypes";
import { SUCCESSFUL_NEW_POST_EVALUATION } from "constants/actionTypes";
import { SHOW_PROGRAMMBS_PROGRESS } from "constants/actionTypes";
import { RESET_PAGINATED_PROGRAMMBS_LIST } from "constants/actionTypes";
import { LOAD_FORM_PROGRAMMBS_VALIDATION } from "constants/actionTypes";
import { SHOW_PROGRAMMBS_VALIDATION } from "constants/actionTypes";
import { LOADING_PROGRAMMBS } from "constants/actionTypes";
import { MBS_PROJECT_LIST } from "constants/actionTypes";
import { LOAD_FORM_PROGRAMMBS, RESET_SHOW_PROGRAMMBS } from "constants/actionTypes";
import { HIDE_REVISION_ALERT, ERROR_SEND_REVISION_PROJECT, SUCCESSFULL_SEND_REVISION_PROJECT, ACTIVE_TAB, DEFAULT_VALUES_PROGRAMMBS } from "constants/actionTypes";
import { PAGINATED_PROGRAMMBS_LIST } from "constants/actionTypes";
const initialState = {
  show_programmbs_progress: {
    requirements: {
      business: "0",
      business_budget: "0",
      checklist: "0",
      income_expense: "0",
      income_statement: "0",
      money_management: "0",
      post_evaluation: "0",
      quality_life: "0",
      service: "0",
      success_story: "0",
    },
    corrections: {},
    total_progress: "0",
  },
  programmbs: {
    plan1: "",
    plan2: "",
    product1: "",
    product3: "",
    product4: "",
    product5: "",
    product6: "",
    product7: "",
    process1: [" "],
    process2: "",
    process3: "",
    process4: "",
    price1: "",
    price2: "",
    price3: "",
    price4: "",
    promotion1: "",
    promotion2: "",
    promotion3: "",
    promotion4: "",
    promotion5: "",
    promotion6: "",
    promotion7: "",
    promotion8: "",
    promotion9: "",
    promotion10: "",
    paperwork1: "",
    paperwork2: "option1",
    paperwork3: { "option1": true },
    paperwork4: { p4_initial_capital: "", p4_expenses: "", p4_income: "", p4_balance: "", p4_array: ["", "", "", "", "", "", "", "", "", ""] },
    paperwork5: { p5_initial_capital: "", p5_array: ["", "", "", ""], p5_balance: "", p5_income: "" },
    paperwork6: { p6_initial_capital: "", p6_expenses: "", p6_earnings_loses: "", p6_balance: "", p6_array: ["", "", "", ""] },
    paperwork7: { p7_month: ["", "", "", "", ""], p7_initial_capital: ["", "", "", "", "", "", "", "", ""], p7_title: ["", "", "", "", "", "", "", "", ""], p7_income: ["", "", "", "", "", "", "", "", ""], p7_array: ["", "", "", "", "", "", "", "", "", ""] },
    paperwork8: { p8_ending_balance: ["", "", "", "", "", "", "", "", ""], p8_profit_loss_balance: ["", "", "", "", "", "", "", "", ""], p8_opening_balance: ["", "", "", "", "", "", "", "", ""], p8_balance: ["", "", "", "", "", "", "", "", ""], p8_expenses: ["", "", "", "", "", "", "", "", ""], p8_earnings_loses: ["", "", "", "", "", "", "", "", ""], p8_array: ["", "", "", "", "", "", "", "", "", ""] },
    paperwork10: {
      p10_month: "",
      p10_income_plan: "",
      p10_income_actual: "",
      p10_expenses_plan: "",
      p10_expenses_actual: "",
      p10_donation_plan: "",
      p10_donation_actual: "",
      p10_saving_plan: "",
      p10_saving_actual: "",
      p10_rent_plan: "",
      p10_rent_actual: "",
      p10_utility_plan: "",
      p10_utility_actual: "",
      p10_food_plan: "",
      p10_food_actual: "",
      p10_clothing_plan: "",
      p10_clothing_actual: "",
      p10_school_plan: "",
      p10_school_actual: "",
      p10_transportation_plan: "",
      p10_transportation_actual: "",
      p10_medical_plan: "",
      p10_medical_actual: "",
      p10_insurance_plan: "",
      p10_insurance_actual: "",
      p10_entertainment_plan: "",
      p10_entertainment_actual: "",
      p10_other_1_plan: "",
      p10_other_1_actual: "",
      p10_other_2_plan: "",
      p10_other_2_actual: "",
      p10_total_expenses_plan: "",
      p10_total_expenses_actual: "",
      p10_total_income_plan: "",
      p10_total_income_actual: "",
      p10_array: ["", "", "", "", "", ""]
    },
    paperwork11: { p11_array: ["", "", "", "", "", ""] },
    paperwork12: "option1",
    paperwork13: false,
    paperwork17: "",
    paperwork18: "",
    quality_p1: "",
    quality_p2: "",
    quality_p3: "",
    quality_p4: "",
    quality_p5: "",
    quality_p6: "",
    quality_p7: "",
    quality_p8: "",
    quality_q1: "274,119",
    quality_q2: "303,132",
    quality_q3: "314,159",
    quality_q4: "303,188",
    quality_q5: "274,199",
    quality_q6: "248,187",
    quality_q7: "234,159",
    quality_q8: "247,132",
    quality_g1: "",
    quality_g2: "",
    quality_g3: "",
    quality_g4: "",
    quality_g5: "",
    quality_g6: "",
    quality_g7: "",
    qualityg8: "",
    service1: "",
    service2: "",
    service3: "",
    service4: "",
    service5: "",
    service6: "",
    service7: "",
    service8: false,
    service9: false,
    history2: "",
    state: "",
    statusplan: "",
    statusproduct: "",
    statusprice: "",
    statuspromotion: "",
    statuspaperwork: "",
    statusprocess: "",
    statusquality: "",
    statusservice: "",
    product_name: "",
    product_description: "",
    product_phone: "",
    product_web: "option.clothing",
    code: "",
    areacode: "1",
    student: {
      id: 142,
      username: "",
      language: "",
      language_grader: [],
      first_name: "",
      last_name: "",
      country: "",
      city: "",
      whatsapp: "",
      roles: [],
      groupes: [],
      studentgroup: {
        id: "",
        group: {
          id: "",
          name: "",
          start_date: "",
          final_date: "",
          graduation_date: "",
          number_students: "",
          modality: "",
          program: "",
          interweave_local: "",
          embassador: {
            id: "",
            username: "",
            language: "",
            language_grader: [],
            first_name: "",
            last_name: "",
            country: "",
            city: "",
            whatsapp: "",
          }
        }
      }
    },
  },
  checklist: [],
  mbs_project_list: [],
  editRevisionError: false,
  editRevisionSuccessfull: false,
  approveProjectError: false,
  approveProjectSuccessfull: false,
  sendRevisionProjectError: false,
  sendRevisionProjectSuccessfull: false,
  newPostEvaluationSuccessful: false,
  activeTab: 0,
  pages_total: "1",
  paginated_programmbs_list: [],
  approved_paginated_programmbs_list: [],
  programmbs_pagination_params: {
    keyword: "",
    page: 1,
    page_size: 10,
    order_column: "updated_at",
    order_direction: "DESC",
  },
  approved_programmbs_pagination_params: {
    keyword: "",
    page: 1,
    page_size: 10,
    order_column: "approval_date",
    order_direction: "DESC",
  },
  loading: true,
}

export const programmbsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PROGRAMMBS:
      var programmbs = action.payload;
      if (programmbs !== undefined && typeof programmbs === "object") {
        programmbs.paperwork9 = programmbs.paperwork3;
      }
      return Object.assign({}, state, {
        programmbs: programmbs,
      });
      case SHOW_PROGRAMMBS_VALIDATION:
        var programmbs = action.payload;
        if (programmbs.modality !== undefined && (programmbs.modality === 'option.modality1' || programmbs.version === '4')) {
          try {
            const { history1, history2, history3, promotion6, product_name, product_description, product_phone, product_web, id } = action.data;
            programmbs = { ...initialState.programmbs, history1, history2, history3, promotion6, product_name, product_description, product_phone, product_web, id };
          } catch (error) {
          }
          return Object.assign({}, state, {
            data: programmbs
          });
        } else {
          if (programmbs !== undefined && typeof programmbs === "object") {
            programmbs.paperwork9 = programmbs.paperwork3;
          }
          return Object.assign({}, state, {
            programmbs: programmbs,
          });
        }  
    case LOAD_FORM_PROGRAMMBS_VALIDATION:
      var programmbs = action.data;
      if (programmbs.modality !== undefined && (programmbs.modality === 'option.modality1' || programmbs.version === '4')) {
        try {
          const { history1, history2, history3, promotion6, product_name, product_description, product_phone, product_web, id } = action.data;
          programmbs = { ...initialState.programmbs, history1, history2, history3, promotion6, product_name, product_description, product_phone, product_web, id };
        } catch (error) {
        }
        return Object.assign({}, state, {
          data: programmbs
        });
      } else {
        programmbs.paperwork9 = programmbs.paperwork3;
        var options = {};

        if (programmbs.paperwork3.length != undefined) {
          programmbs.paperwork3.forEach(element => {
            options[element] = true;
          });
          programmbs.paperwork3 = options;
        }

        var checklist = {};
        if (programmbs.checklist.length != undefined) {
          programmbs.checklist.forEach(element => {
            checklist[element] = true;
          })
          programmbs.checklist = checklist;
        }

        return Object.assign({}, state, {
          data: programmbs
        });
      }

    case UPDATE_REVISION_PROGRAMMBS:
      return Object.assign({}, state, {
        programmbs: Object.assign(state.programmbs, {
          [action.payload.name]: action.payload.value
        })
      });

    case LOAD_FORM_PROGRAMMBS:
      var programmbs = action.data;
      programmbs.paperwork9 = programmbs.paperwork3;
      var options = {};

      if (programmbs.paperwork3.length != undefined) {
        programmbs.paperwork3.forEach(element => {
          options[element] = true;
        });
        programmbs.paperwork3 = options;
      }

      var checklist = {};
      if (programmbs.checklist.length != undefined) {
        programmbs.checklist.forEach(element => {
          checklist[element] = true;
        })
        programmbs.checklist = checklist;
      }

      return Object.assign({}, state, {
        data: programmbs
      });


    case ERROR_EDIT_REVISION:
      return Object.assign({}, state, {
        editRevisionError: true
      });

    case SUCCESSFULL_EDIT_REVISION:
      return Object.assign({}, state, {
        editRevisionSuccessfull: true
      });

    case ERROR_APPROVE_PROJECT:
      return Object.assign({}, state, {
        approveProjectError: true
      });

    case SUCCESSFULL_APPROVE_PROJECT:
      return Object.assign({}, state, {
        approveProjectSuccessfull: true
      });

    case ERROR_SEND_REVISION_PROJECT:
      return Object.assign({}, state, {
        sendRevisionProjectError: true
      });

    case SUCCESSFULL_SEND_REVISION_PROJECT:
      return Object.assign({}, state, {
        sendRevisionProjectSuccessfull: true
      });
    case SUCCESSFUL_NEW_POST_EVALUATION:
      return Object.assign({}, state, {
        newPostEvaluationSuccessful: true
      });

    case HIDE_REVISION_ALERT:
      return Object.assign({}, state, {
        editRevisionError: false,
        editRevisionSuccessfull: false,
        approveProjectError: false,
        approveProjectSuccessfull: false,
        sendRevisionProjectError: false,
        sendRevisionProjectSuccessfull: false,
        newPostEvaluationSuccessful: false
      });

    case ACTIVE_TAB:
      return Object.assign({}, state, {
        activeTab: action.key
      });

    case RESET_SHOW_PROGRAMMBS:
      return Object.assign({}, state, {
        programmbs: Object.assign(state.programmbs, {
          ["paperwork4"]: {},
          ["paperwork5"]: {},
          ["paperwork6"]: {},
          ["paperwork7"]: {},
          ["paperwork8"]: {},
          ["paperwork10"]: {},
          ["paperwork11"]: {},
        })
      });

    case DEFAULT_VALUES_PROGRAMMBS:
      var programmbs = initialState.programmbs;
      return Object.assign({}, state, {
        programmbs: programmbs,
      });

    case MBS_PROJECT_LIST:
      return Object.assign({}, state, {
        mbs_project_list: action.payload,
        loading: false,
      });
    case SHOW_PROGRAMMBS_PROGRESS:
      return Object.assign({}, state, {
        show_programmbs_progress: action.payload,
      });
    case PAGINATED_PROGRAMMBS_LIST:
      return Object.assign({}, state, {
        paginated_programmbs_list: action.payload.list,
        pages_total: action.payload.pages_total,
        loading: false
      });
    case RESET_PAGINATED_PROGRAMMBS_LIST:
      return Object.assign({}, state, {
        paginated_programmbs_list: [],
        pages_total: "1",
      });
    case LOADING_PROGRAMMBS:
      return Object.assign({}, state, {
        loading: true
      });
  }
  return state;
}


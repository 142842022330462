import { ERROR_REQUIRED_FIELDS, SUCCESS_REQUIRED_FIELDS} from "constants/actionTypes";
import { SUCCESSFULL_EDIT, SUCCESSFUL_DELETE  } from "constants/actionTypes";
import { IDLE_TIMER } from "constants/actionTypes";
import { ERROR_EVALUATION } from "constants/actionTypes";
import { DELETE_WARNING, GROUP_DELETE_WARNING } from "constants/actionTypes";
import { SUCCESSFUL_LOAD } from "constants/actionTypes";
import { RESPONSE_OBTAINED } from "constants/actionTypes";
import { UPDATE_NAVPILLS_TAB } from "constants/actionTypes";
import { DUPLICATE_ERROR_EVALUATION } from "constants/actionTypes";
import { SAVED_LINK } from "constants/actionTypes";
import { WAITING_RESPONSE } from "constants/actionTypes";
import { LOADING_SEARCH } from "constants/actionTypes";
import { CLEAN_LOADING_SEARCH } from "constants/actionTypes";
import { CLEAN_SESSION_EXPIRED } from "constants/actionTypes";
import { SESSION_EXPIRED } from "constants/actionTypes";
import { RESET_WAITING_RESPONSE } from "constants/actionTypes";
import { SUGGESTION_WARNING } from "constants/actionTypes";
import { DELETE_SAVED_LINK } from "constants/actionTypes";
import { SUCCESSFUL_SAVE } from "constants/actionTypes";
import { UPDATE_WIZARD_TAB } from "constants/actionTypes";
import { SUCCESSFULL_REDIRECT } from "constants/actionTypes";
import { ACTION_WARNING } from "constants/actionTypes";
import { SHOW_PROFILE_UPDATE } from "constants/actionTypes";
import { ALREADY_EXIST_USER } from "constants/actionTypes";
import { CHANGE_ACTIVE_TAB } from "constants/actionTypes";
import { DISMATCH_PASSWORD, SUCCESSFULL_EDIT_CLEAN } from "constants/actionTypes";
import { SUCCESSFULL_NEW, DELETE_SUCCESSFUL, UPDATE_FILE_NAME, SUCCESSFUL_NEW_ARRAY, SUCCESSFUL_SEND } from "constants/actionTypes";
import { ERROR_SUBMIT, SUCCESS_SUBMIT, CLEAR_PROFILE_UPDATE, CHANGE_VERTICAL_ACTIVE_TAB} from "constants/actionTypes";
import { truncate } from "lodash";

const initialState = {
  idle_timer_modal: false, 
  error_new_user: false,
  successfull_edit: false,
  successful_delete: false,
  successful_new: false,
  successful_send: false,
  successful_new_array: false,
  errorRequired: false,
  submitError: false,
  successRequired: false,
  dismatch_password:false,
  error_evaluation: false,
  profile_update: false,
  delete:false, 
  delete_warning:false,
  action_warning:false,
  group_delete_warning:false,
  active_tab: 0,
  vertical_active_tab: 0,
  navpills_active_tab: 0,
  wizard_active_tab: 0,
  successful_load: false,
  request_obtained: false,
  duplicate_error_evaluation: false,
  successful_save: false,
  saved_link:"#",
  waiting_response: false,
  suggestion_warning: false,
  loading_search: false,
  session_expired: false,
}

export const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR_REQUIRED_FIELDS:
            return Object.assign({}, state, {
              errorRequired: true,
            });
        case ERROR_SUBMIT:
          return Object.assign({}, state, {
            errorSubmit: true,
          });
        case SUCCESS_SUBMIT:
          return Object.assign({}, state, {
            errorSubmit: false,
          });
        case SUCCESS_REQUIRED_FIELDS:
            return Object.assign({}, state, {
              successRequired: true,
              errorRequired: false,
            });
        case SUCCESSFULL_EDIT:
          return Object.assign({}, state, {
            successfull_edit: true
          }); 
        case SUCCESSFUL_DELETE:
          return Object.assign({}, state, {
            successful_delete: false
          }); 
        case DELETE_SUCCESSFUL:
          return Object.assign({}, state, {
            delete: true,
            successfull_edit: false,
            successfull_new: false,
            successful_send: false,
            dismatch_password: false,
            successful_new_array: false,
            errorSubmit: false,
            error_new_user: false,
            error_evaluation: false,
            delete_warning: false,
            action_warning: false,
            group_delete_warning: false,
            successful_load: false,
            response_obtained: false,
            duplicate_error_evaluation: false,
            successful_save: false,
            idle_timer_modal: false,
            suggestion_warning: false,
          });
          case SUCCESSFULL_REDIRECT:
            return Object.assign({}, state, {
              delete: true,
              successfull_edit: false,
              successfull_new: false,
              successful_send: false,
              dismatch_password: false,
              successful_new_array: false,
              errorSubmit: false,
              error_new_user: false,
              error_evaluation: false,
              delete_warning: false,
              action_warning: false,
              group_delete_warning: false,
              successful_load: false,              
            });
        case DISMATCH_PASSWORD:
          return Object.assign({}, state, { 
            dismatch_password: true
          }); 
        case SUCCESSFULL_NEW:
          return Object.assign({}, state, {
            successfull_new: true
          });
        case SUCCESSFUL_NEW_ARRAY:
          return Object.assign({}, state, {
            successful_new_array: true
          });
        case SUCCESSFUL_SEND:
          return Object.assign({}, state, {
            successful_send: true
          });       
        case SUCCESSFULL_EDIT_CLEAN:
          return Object.assign({}, state, {
            successfull_edit: false
          });
        case UPDATE_FILE_NAME:
          var element = "groupNewForm";
          return Object.assign({}, state, {
            enviando: element
          });
        case UPDATE_NAVPILLS_TAB:
          return Object.assign({}, state, {
            navpills_active_tab: action.payload
          });
        case UPDATE_WIZARD_TAB:
          return Object.assign({}, state, {
            wizard_active_tab: action.payload
          });
        case CHANGE_ACTIVE_TAB:
          return Object.assign({}, state, {
            active_tab: action.payload
          });
        case CHANGE_VERTICAL_ACTIVE_TAB:
          return Object.assign({}, state, {
            vertical_active_tab: action.payload
          });

        case IDLE_TIMER:
          return Object.assign({}, state, {
            idle_timer_modal: true
          });

        case ALREADY_EXIST_USER:
          return Object.assign({}, state, {
            error_new_user: true
          });

        case ERROR_EVALUATION:
          return Object.assign({}, state, {
            error_evaluation: true
          });

        case SHOW_PROFILE_UPDATE:
          return Object.assign({}, state, {
            profile_update: true
          });

        case CLEAR_PROFILE_UPDATE:
          return Object.assign({}, state, {
            profile_update: false,
            successfull_edit: false,
          });

        case DELETE_WARNING:
          return Object.assign({}, state, {
            delete_warning: true,
          });

        case ACTION_WARNING:
          return Object.assign({}, state, {
            action_warning: true,
          });

        case GROUP_DELETE_WARNING:
          return Object.assign({}, state, {
            group_delete_warning: true,
          });
        
        case SUCCESSFUL_LOAD:
          return Object.assign({}, state, {
            successful_load: true,
          });

        case RESPONSE_OBTAINED:
          return Object.assign({}, state, {
            response_obtained: true,
          });

        case DUPLICATE_ERROR_EVALUATION:
          return Object.assign({}, state, {
            duplicate_error_evaluation: true,
          });
        case SUCCESSFUL_SAVE:
          return Object.assign({}, state, {
            successful_save: true,
          });
        case SAVED_LINK:
          return Object.assign({}, state, {
            saved_link: action.payload
          });
        case DELETE_SAVED_LINK:
          return Object.assign({}, state, {
            saved_link: "#"
          });
        case WAITING_RESPONSE:
          return Object.assign({}, state, {
            waiting_response: true,
          });
        case LOADING_SEARCH:
          return Object.assign({}, state, {
            loading_search: true,
          });
        case CLEAN_LOADING_SEARCH:
          return Object.assign({}, state, {
            loading_search: false,
          });
        case RESET_WAITING_RESPONSE:
          return Object.assign({}, state, {
            waiting_response: false,
          });
        case SUGGESTION_WARNING:
          return Object.assign({}, state, {
            suggestion_warning: true,
          });
        case SESSION_EXPIRED:
          return Object.assign({}, state, {
            session_expired: true,
          });
        case CLEAN_SESSION_EXPIRED:
          return Object.assign({}, state, {
            session_expired: false,
          });
        }
        
        
     return state;
}
  

import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from "react-table";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import { DebounceInput } from 'react-debounce-input';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomDebounceInput from 'components/CustomInput/CustomDebounceInput.jsx';

// @material-ui/icons
import Create from "@material-ui/icons/Create";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Launch from "@material-ui/icons/Launch";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";


import { translate } from 'react-switch-lang';
import { loadingSearch } from "actions/generalActions";
import { cleanLoadingSearch } from "actions/generalActions";
import { getStudentPaginationList } from "actions/studentActions";
import { numericShowDate } from "assets/functions/general";
import { loadingStudent } from "actions/studentActions";
import { avatarAuthenticationWarning } from "actions/loginActions";
import { getAvatarAuthenticacion } from "actions/loginActions.jsx";
import { cleanAvatarAuthenticationWarning } from "actions/loginActions.jsx";
import { showDate } from "assets/functions/general";

const styles = {
  tooltip: {
    maxWidth: 500,
  },
  ...tooltip,
  ...sweetAlertStyle
};

class IndexTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtered: [],
      filterAll: '',

    };
    this.filterAll = this.filterAll.bind(this);
  }

  filterAll(e) {
    const { value } = e.target;
    this.props.dispatchLoadingSearch();
    let pagination_params = this.props.student_pagination_params;
    pagination_params.keyword = value;
    pagination_params.page = 1;
    pagination_params.page_size = 10;
    this.props.dispatchGetStudentPaginationList();
  }


  componentDidMount() {
    this.props.dispatchGetStudentPaginationList();
    this.props.dispatchLoadingStudent();
  }
  handleOnPageChange(page) {
    this.props.dispatchLoadingStudent();
    let pagination_params = this.props.student_pagination_params;
    pagination_params.page = page + 1;
    this.props.dispatchGetStudentPaginationList();
  }
  handleOnPageSizeChange(size) {
    this.props.dispatchLoadingStudent();
    let pagination_params = this.props.student_pagination_params;
    pagination_params.page_size = size;
    this.props.dispatchGetStudentPaginationList();
  }
  handleOnSortedChange(sorted) {
    this.props.dispatchLoadingStudent();
    let pagination_params = this.props.student_pagination_params;
    pagination_params.order_column = sorted[0].id;
    pagination_params.order_direction = sorted[0].desc === false ? "ASC" : "DESC";
    pagination_params.page = 1;
    this.props.dispatchGetStudentPaginationList();

  }
  dispatchAvatarAuthenticationWarning(params) {
    this.props.dispatchAvatarAuthenticationWarning(params);
  }

  dispatchCleanAvatarAuthentication() {
    this.props.dispatchCleanAvatarAuthentication();
  }

  dispatchGetAvatarAuthentication($id) {
    this.props.dispatchGetAvatarAuthentication($id);
  }

  render() {
    const { paginated_student_list, loading, classes, student_pagination_params, pages_total, loading_search, avatar_authentication_warning, user_id, user_name } = this.props;
    let { t } = this.props;
    const data = paginated_student_list.map((prop, key) => {
      let buttonMbs = false;
      let buttonSa = false;
      let buttonWell = false;
      if (prop.programsa !== undefined) {
        buttonSa = true;
      }
      if (prop.programmbs !== undefined) {
        buttonMbs = true;
      }
      if (prop.programwell !== undefined) {
        buttonWell = true;
      }
      let params = {
        user_id: prop.id,
        user_name: prop.participant_name
      }
      return {
        id: key,
        participant_name: (
          <Tooltip
            id="tooltip-top"
            title={
              <div>
                {t("label_role") + ": "}{t(prop.role[0])}
              </div>
            }
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div>{prop.participant_name}</div>
          </Tooltip>
        ),
        created_at: (
          <Tooltip
            id="tooltip-top"
            title={
              prop.group_1 !== "" || prop.group_2 !== "" ?
                <div>
                  {t("label_group") + ": "}
                  {prop.group_1 !== "" && prop.group_2 !== "" ? prop.group_1 + "/" + prop.group_2 : prop.group_1 !== "" ? prop.group_1 : prop.group_2}
                </div> : ""
            }

            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <div>{numericShowDate(prop.created_at)}</div>
          </Tooltip>
        ),
        country: prop.country,
        whatsapp: prop.whatsapp,
        actions: (
          <div className="actions-left">
            <Link to={"/student/show/" + prop.id}>
              <Button
                justIcon
                round4
                simple
                color="info"
              >
                <Visibility />
              </Button>
            </Link>
            <Link to={"/student/edit/" + prop.id}>
              <Button
                justIcon
                round
                simple
                color="warning"
              >
                <Create />
              </Button>
            </Link>
            <Link to={"/student/show/" + prop.id}>
              <Button
                justIcon
                round
                simple
                color="danger"
              >
                <Close />
              </Button>
            </Link>
            <Tooltip
              id="tooltip-top"
              title={t("label_group_change")}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={"/student/groupchange/" + prop.id}>
                <Button
                  justIcon
                  round
                  simple
                  color="twitter"
                >
                  <SwapHoriz />
                </Button>
              </Link>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              title={t("label_authentication_as") + " " + prop.participant_name}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                justIcon
                round
                simple
                color="success"
                onClick={() => this.dispatchAvatarAuthenticationWarning(params)}
              >
                <Launch />
              </Button>
            </Tooltip>
          </div>
        ),
        projects: (
          <div className="actions-left">
            <Tooltip
              id="tooltip-top"
              title={<>
                <div>{t("label_mbs_requirements_progress") + ": " + Math.round(prop.mbs_requirements_progress) + "%"}</div>
                <div>{t("label_mbs_requirements_state") + ": " + (prop.programmbs === undefined ? "Not Started" : t(prop.programmbs.state))}</div>
                {prop.mbs_sa_course && prop.group_program === "option.program7" ?
                  <>
                    <div>{t("label_mbs_course_progress") + ": " + Math.round(prop.mbs_sa_course.course_progress) + "%"}</div>
                    <div>{`${t("label_course")}: ${prop.mbs_sa_course.course_name === "not_available" ? t("state.not_available") : t(prop.mbs_sa_course.course_name)}`} </div>
                    {prop.mbs_sa_course.unit_name && <div>{t("label_current_unit") + ": " + t(prop.mbs_sa_course.unit_name)} </div>}
                    {prop.mbs_sa_course.principle_name && <div>{t("label_current_principle") + ": " + t(prop.mbs_sa_course.principle_name)} </div>}
                    {prop.mbs_sa_course.progress_date && <div>{t("label_last_progress_date") + ": " + showDate(prop.mbs_sa_course.progress_date)} </div>}
                    {prop.mbs_sa_course.stop_state && <div>{t("label_stop_state") + ": " + t(prop.mbs_sa_course.stop_state)} </div>}
                  </>

                  : prop.mbs_course.course_name &&
                  <>
                    <div>{t("label_mbs_course_progress") + ": " + Math.round(prop.mbs_course.course_progress) + "%"}</div>
                    <div>{`${t("label_course")}: ${prop.mbs_course.course_name === "not_available" ? t("state.not_available") : t(prop.mbs_course.course_name)}`} </div>
                    {prop.mbs_course.unit_name && <div>{t("label_current_unit") + ": " + t(prop.mbs_course.unit_name)} </div>}
                    {prop.mbs_course.principle_name && <div>{t("label_current_principle") + ": " + t(prop.mbs_course.principle_name)} </div>}
                    {prop.mbs_course.progress_date && <div>{t("label_last_progress_date") + ": " + showDate(prop.mbs_course.progress_date)} </div>}
                    {prop.mbs_course.stop_state && <div>{t("label_stop_state") + ": " + t(prop.mbs_course.stop_state)} </div>}
                  </>
                }
              </>}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Link
                to={
                  buttonMbs ? (
                    prop.programmbs.modality === "option.modality1" ?
                      "/programmbs/showfile/" + prop.programmbs.id :
                      (prop.group_program === "option.program7" || prop.programmbs.version === "5") ?
                        "/course/requirement/mbs/show/" +
                        (prop.mbs_sa_course && prop.mbs_sa_course.course_progress > 0 ?
                          (prop.mbs_sa_course.id ? prop.mbs_sa_course.id : "-")
                          :
                          (prop.mbs_course && prop.mbs_course.course_progress > 0 && prop.mbs_course.id ? prop.mbs_course.id : "-")
                        ) +
                        "/" + prop.id :
                        "/programmbs/show/" + prop.programmbs.id
                  ) : (
                    prop.mbs_course && prop.mbs_course.course_progress > 0 ?
                      `/course/participant/ambassadorparticipantsanswer/${prop.mbs_course.id}/${prop.id}` :
                      "#"
                  )
                }
              >
                <Button
                  size="sm"
                  color={buttonMbs ? (prop.group_program === "option.program4" ? "warning" : "success") : prop.mbs_course.course_progress > 0 ? "success" : "default"}
                >
                  {prop.group_program === "option.program4" ? t('button_mbs_jr') : t('button_mbs')}
                </Button>
              </Link>
            </Tooltip>
            {" "}
            <Tooltip
              id="tooltip-top"
              title={<>
                <div>{t("label_sa_requirements_progress") + ": " + Math.round(prop.sa_requirements_progress) + "%"}</div>
                <div>{t("label_sa_requirements_state") + ": " + (prop.programsa === undefined ? "Not Started" : t(prop.programsa.state))}</div>

                {prop.mbs_sa_course && prop.group_program === "option.program7" ?
                  <>
                    <div>{t("label_sa_course_progress") + ": " + Math.round(prop.mbs_sa_course.course_progress) + "%"}</div>
                    <div>{`${t("label_course")}: ${prop.mbs_sa_course.course_name === "not_available" ? t("state.not_available") : t(prop.mbs_sa_course.course_name)}`} </div>
                    {prop.mbs_sa_course.unit_name && <div>{t("label_current_unit") + ": " + t(prop.mbs_sa_course.unit_name)} </div>}
                    {prop.mbs_sa_course.principle_name && <div>{t("label_current_principle") + ": " + t(prop.mbs_sa_course.principle_name)} </div>}
                    {prop.mbs_sa_course.progress_date && <div>{t("label_last_progress_date") + ": " + showDate(prop.mbs_sa_course.progress_date)} </div>}
                    {prop.mbs_sa_course.stop_state && <div>{t("label_stop_state") + ": " + t(prop.mbs_sa_course.stop_state)} </div>}
                  </>

                  : prop.sa_course.course_name &&
                  <>
                    <div>{t("label_sa_course_progress") + ": " + Math.round(prop.sa_course.course_progress) + "%"}</div>
                    <div>{`${t("label_course")}: ${prop.sa_course.course_name === "not_available" ? t("state.not_available") : t(prop.sa_course.course_name)}`} </div>
                    {prop.sa_course.unit_name && <div>{t("label_current_unit_name") + ": " + t(prop.sa_course.unit_name)} </div>}
                    {prop.sa_course.principle_name && <div>{t("label_current_principle_name") + ": " + t(prop.sa_course.principle_name)} </div>}
                    {prop.sa_course.progress_date && <div>{t("label_course_progress_date") + ": " + showDate(prop.sa_course.progress_date)} </div>}
                    {prop.sa_course.stop_state && <div>{t("label_stop_state") + ": " + t(prop.sa_course.stop_state)} </div>}
                  </>

                }
              </>}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={buttonSa ?
                (prop.programsa.modality === "option.modality1"
                  ? "/programsa/showfile/" + prop.programsa.id : "/course/requirement/programsa/show/" +
                  (prop.mbs_sa_course && prop.mbs_sa_course.course_progress > 0 ?
                    (prop.mbs_sa_course.id ? prop.mbs_sa_course.id : "-")
                    :
                    (prop.sa_course.id && prop.sa_course.course_progress > 0 ? prop.sa_course.id : "-")
                  )


                  + "/" + prop.id)
                : prop.sa_course.course_progress > 0 ? `/course/participant/ambassadorparticipantsanswer/${prop.sa_course.id}/${prop.id}` : "#"}>
                <Button
                  size="sm"
                  color={buttonSa ? "info" : prop.sa_course.course_progress > 0 ? "info" : "default"}
                >
                  {t('SA')}
                </Button>
              </Link>
            </Tooltip>
            {" "}
            <Tooltip
              id="tooltip-top"
              title={<>
                
                <div>{t("label_well_requirements_progress") + ": " + Math.round(prop.well_requirements_progress) + "%"}</div>
                <div>{t("label_well_requirements_state") + ": " + (prop.programwell === undefined ? "Not Started" : t(prop.programwell.state))}</div>
                <div>{t("label_well_course_progress") + ": " + Math.round(prop.well_course.course_progress) + "%"}</div>

                {prop.well_course.course_name && <div>{`${t("label_course")}: ${prop.well_course.course_name === "not_available" ? t("state.not_available") : t(prop.well_course.course_name)}`} </div>}
                {prop.well_course.unit_name && <div>{t("label_current_unit_name") + ": " + t(prop.well_course.unit_name)} </div>}
                {prop.well_course.principle_name && <div>{t("label_current_principle_name") + ": " + t(prop.well_course.principle_name)} </div>}
                {prop.well_course.progress_date && <div>{t("label_course_progress_date") + ": " + showDate(prop.well_course.progress_date)} </div>}
                {prop.well_course.stop_state && <div>{t("label_stop_state") + ": " + t(prop.well_course.stop_state)} </div>}
              </>}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={buttonWell
                ? "/course/requirement/wellness/show/" + (prop.well_course.id && prop.sa_course.course_progress > 0 ? prop.well_course.id : "-") + "/" + prop.id
                : prop.well_course.course_progress > 0 ? `/course/participant/ambassadorparticipantsanswer/${prop.well_course.id}/${prop.id}` : "#"}>
                <Button
                  size="sm"
                  color={buttonWell ? "twitter" : prop.well_course.course_progress > 0 ? "twitter" : "default"}
                >
                  {t('WELL')}
                </Button>
              </Link>
            </Tooltip>

          </div>
        ),
      };
    });



    return (
      <GridContainer>
        {avatar_authentication_warning ?
          <SweetAlert
            warning
            showCancel={true}
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() => this.dispatchGetAvatarAuthentication(user_id)}
            onCancel={() => this.dispatchCleanAvatarAuthentication()}
            confirmBtnText={t("button_yes")}
            cancelBtnText={t("button_no")}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.info
            }
          >
            <h4>{t("label_avatar_authentication_warning") + " " + user_name + " ?"}</h4>
          </SweetAlert>
          : ""
        }
        <GridItem xs={12}>
          <DebounceInput
            element={CustomDebounceInput}
            onChange={event => this.filterAll(event)}
            minLength={2}
            debounceTimeout={300}
            inputProps={{
              placeholder: "Search",
              defaultValue: student_pagination_params.keyword
            }}
          />
          {loading_search ? " " + "Loading..." : ""}

          <ReactTable
            data={data}
            loading={loading}
            columns={[
              {
                Header: t("th_name"),
                accessor: "participant_name",
                width: 220,
                resizable: false,
              },
              {
                Header: t("label_date"),
                accessor: "created_at",
                width: 85,
                resizable: false,
              },
              {
                Header: t("th_country"),
                accessor: "country",
                width: 90,
                resizable: false,
              },
              {
                Header: t("th_whatsapp"),
                accessor: "whatsapp",
                width: 160,
                resizable: false,
              },
              {
                Header: t("th_projects"),
                accessor: "projects",
                sortable: false,
                filterable: false,
                width: 240,
              },
              {
                Header: t("th_actions"),
                accessor: "actions",
                sortable: false,
                resizable: false,
                filterable: false,
                width: 240,
              },
              {

                Header: "",
                id: 'all',
                width: 0,
                resizable: false,
                sortable: false,

                getProps: () => {
                  return {
                    style: { padding: "5px" }
                  }
                },
              }
            ]}
            key={data.length}
            manual
            pages={pages_total}
            page={parseInt(student_pagination_params.page) - 1}
            defaultPageSize={data.length < 10 ? data.length : 10}
            pageSize={student_pagination_params.page_size}
            showPaginationTop={false}
            showPaginationBottom={true}
            onPageChange={(pageIndex) => this.handleOnPageChange(pageIndex)}
            onPageSizeChange={(size) => this.handleOnPageSizeChange(size)}
            onSortedChange={(newSorted) => this.handleOnSortedChange(newSorted)}
            className="-striped -highlight"
          />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.studentReducer.loading,
  pages_total: state.studentReducer.pages_total,
  loading_search: state.generalReducer.loading_search,
  paginated_student_list: state.studentReducer.paginated_student_list,
  student_pagination_params: state.studentReducer.student_pagination_params,
  avatar_authentication_warning: state.loginReducer.avatar_authentication_warning,
  user_id: state.loginReducer.user_id,
  user_name: state.loginReducer.user_name
});

const mapDispatchToPropsActions = dispatch => ({
  dispatchGetStudentPaginationList: () => dispatch(getStudentPaginationList()),
  dispatchLoadingSearch: () => dispatch(loadingSearch()),
  dispatchCleanLoadingSearch: () => dispatch(cleanLoadingSearch()),
  dispatchLoadingStudent: () => dispatch(loadingStudent()),
  dispatchAvatarAuthenticationWarning: (params) => dispatch(avatarAuthenticationWarning(params)),
  dispatchGetAvatarAuthentication: (id) => dispatch(getAvatarAuthenticacion(id)),
  dispatchCleanAvatarAuthentication: () => dispatch(cleanAvatarAuthenticationWarning()),
});

const IndexTableComponent = translate(withStyles(styles)(IndexTable));
export default connect(mapStateToProps, mapDispatchToPropsActions)(IndexTableComponent);


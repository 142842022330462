import { createStore, compose, applyMiddleware } from 'redux';
import { forbiddenWordsMiddleware } from 'middleware/Index.jsx';
import { loadState, saveState } from "actions/actions.jsx";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import indexReducer from 'reducers/index.jsx';
import thunk from 'redux-thunk';


const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = { key: "root", storage };

const persistedReducer = persistReducer(persistConfig, indexReducer);


const persistedState = loadState();
export const store = createStore(
  persistedReducer,
  persistedState,
  storeEnhancers(applyMiddleware(thunk, createStateSyncMiddleware({ blacklist: ["persist/PERSIST", "persist/REHYDRATE"] }) ))
);

initMessageListener(store);


store.subscribe(() => {
  saveState({
    todos: store.getState().todos
  });
});
import { STUDENT_LIST, SHOW_STUDENT, LOAD_FORM_STUDENT, DELETE_STUDENT, EDIT_STUDENT, ERROR_EDIT_STUDENT, SUCCESSFULL_EDIT } from 'constants/actionTypes.jsx';
import { EDIT_PASSWORD_STUDENT, SUCCESSFULL_EDIT_CLEAN, DASHBOARD_STUDENT, NEW_STUDENT } from 'constants/actionTypes';
import { GET_STUDENT_AMBASSADOR, SUCCESSFULL_NEW, EVALUATION_PRE, EVALUATION_POST, MBS_STUDENT_LIST } from 'constants/actionTypes';
import { SUCCESS_STORY, ALREADY_EXIST_USER } from 'constants/actionTypes';
import { VIDEO_PROMOTION } from 'constants/actionTypes';
import history from '../history';
import { SEARCH_LOADING } from 'constants/actionTypes';
import { INACTIVE_STUDENT_LIST } from 'constants/actionTypes';
import { AVAILABLE_COURSE_LIST } from 'constants/actionTypes';
import { showProgressNextPending, showUnitListPublished } from './courseActions';
import { UNIT_STUDENT_LIST } from 'constants/actionTypes';
import { NEW_PROGRESS } from 'constants/actionTypes';
import { STUDENT_LIST_COURSE } from 'constants/actionTypes';
import { ENDROLLED_COURSE_PARTICIPANT_LIST } from 'constants/actionTypes';
import { UPDATE_PARTICIPANT_NAME } from 'constants/actionTypes';
import { EDIT_PROGRESS } from 'constants/actionTypes';
import { SHOW_STOP } from 'constants/actionTypes';
import { RESET_SHOW_STOP } from 'constants/actionTypes';
import { COURSE_STUDENT_STOP_LIST } from 'constants/actionTypes';
import { GROUP_STUDENT_LIST } from 'constants/actionTypes';
import { RESET_WAITING_PRINCIPLE_LOAD } from 'constants/actionTypes';
import { RESET_WAITING_RESPONSE } from 'constants/actionTypes';
import { PAGINATED_STUDENT_LIST } from 'constants/actionTypes';
import { CLEAN_LOADING_SEARCH } from 'constants/actionTypes';
import { LOADING_STUDENT } from 'constants/actionTypes';
import { PAGINATED_SUCCESS_STORY_LIST } from 'constants/actionTypes';
import { SESSION_EXPIRED } from 'constants/actionTypes';
import { DELETE_SUCCESSFUL } from 'constants/actionTypes';
import { getActiveUser } from "actions/loginActions.jsx";
import { PAGINATED_POTENTIALSA_LIST } from 'constants/actionTypes';
import { PAGINATED_STUDENT_GRANTMEMBER_LIST } from 'constants/actionTypes';
import { PAGINATED_STUDENT_AMBASSADOR_LIST } from 'constants/actionTypes';

export const getStudentList = key => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/student/?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: STUDENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const groupStudentList = key => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/student/list/group/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GROUP_STUDENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const searchList = key => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        dispatch({ type: SEARCH_LOADING });
        return fetch(reduxState.loginReducer.base_url + "/student/search/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: INACTIVE_STUDENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const searchPaginationList = (key) => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("key", key);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/student/search/pagination", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: INACTIVE_STUDENT_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getMbsStudentList = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/student/mbs/?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: MBS_STUDENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showStudent = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/user/show/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_STUDENT, payload: json.data });
                    dispatch({ type: LOAD_FORM_STUDENT, data: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT_CLEAN })
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const dashboardStudent = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const id = reduxState.loginReducer.active_user.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/student/dashboard/" + id + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DASHBOARD_STUDENT, payload: json.data });
                    if (reduxState.loginReducer.active_user.idnumber === "undefined" || reduxState.loginReducer.active_user.idnumber === undefined) {
                        //dispatch ({ type: SHOW_PROFILE_UPDATE })
                    }
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const loadFormStudent = data => ({ type: LOAD_FORM_STUDENT, data });

export const deleteStudent = (key, redirect) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        
        return fetch(reduxState.loginReducer.base_url + "/user/delete/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DELETE_STUDENT, payload: json.data });
                    redirect.push('/student');
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}
export const newStudent = () => {
    return (dispatch, getState) => {

        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", reduxState.form.studentform.values.username);
        urlencoded.append("language", reduxState.form.studentform.values.language);
        urlencoded.append("first_name", reduxState.form.studentform.values.first_name);
        urlencoded.append("last_name", reduxState.form.studentform.values.last_name);
        urlencoded.append("country", reduxState.form.studentform.values.country);
        urlencoded.append("city", reduxState.form.studentform.values.city);
        urlencoded.append("whatsapp", reduxState.form.studentform.values.whatsapp);
        urlencoded.append("group", reduxState.form.studentform.values.id_group);
        urlencoded.append("areacode", reduxState.form.studentform.values.areacode);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/student/new?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: RESET_WAITING_RESPONSE });
                    if (json.code === 600) {
                        dispatch({ type: NEW_STUDENT, payload: json.data });
                        dispatch({ type: ALREADY_EXIST_USER });
                    }
                    else {
                        dispatch({ type: NEW_STUDENT, payload: json.data });
                        dispatch({ type: SUCCESSFULL_NEW });
                        return history.push('/student/show/' + json.data.id);

                    }
                }
            })
            .catch(json => {
                dispatch({ type: ERROR_EDIT_STUDENT })
            })
    }
};

export const editStudent = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.form.studentform.values.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", reduxState.form.studentform.values.username);
        urlencoded.append("language", reduxState.form.studentform.values.language);
        urlencoded.append("firstName", reduxState.form.studentform.values.first_name);
        urlencoded.append("lastName", reduxState.form.studentform.values.last_name);
        urlencoded.append("country", reduxState.form.studentform.values.country);
        urlencoded.append("city", reduxState.form.studentform.values.city);
        urlencoded.append("whatsapp", reduxState.form.studentform.values.whatsapp);
        urlencoded.append("picture", reduxState.form.studentform.values.picture);
        urlencoded.append("idnumber", reduxState.form.studentform.values.idnumber);
        urlencoded.append("areacode", reduxState.form.studentform.values.areacode);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/user/edit/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_STUDENT, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                    dispatch(getActiveUser());
                }
            })
            .catch(json => {
                dispatch({ type: ERROR_EDIT_STUDENT })
            })
    }
};

export const editPassword = (params, key) => {
    return (dispatch, getState) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("password", params.userPassword);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        const reduxState = getState();
        return fetch(reduxState.loginReducer.base_url + "/user/editpassword/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                dispatch({ type: EDIT_PASSWORD_STUDENT, payload: json });
                dispatch({ type: SUCCESSFULL_EDIT });
            })
    }
}

export const groupChange = (params, key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("student", params.student);
        urlencoded.append("group", params.group);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/student/groupswitch" + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getStudentAmbassadorList = (key) => {
    return (dispatch, getState) => {

        var id = "";
        const reduxState = getState();
        if (reduxState.loginReducer.active_user.roles[0] == "ROLE_EMBASSADOR") {
            id = reduxState.loginReducer.active_user.id;
        }
        else if (reduxState.loginReducer.active_user.roles[0] == "ROLE_ADMIN") {
            id = key
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/student/ambassador/all", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_STUDENT_AMBASSADOR, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showStudentRedirect = (redirect) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.studentReducer.new_student.id
        dispatch({ type: SUCCESSFULL_NEW });
        return redirect.push('/student/show/' + key);
    }
}

export const evaluationPre = (program) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id_student", reduxState.loginReducer.active_user.id);
        urlencoded.append("question1", reduxState.form.preform.values.question1);
        urlencoded.append("question2", reduxState.form.preform.values.question2);
        urlencoded.append("question3", reduxState.form.preform.values.question3);
        urlencoded.append("question4", reduxState.form.preform.values.question4);
        urlencoded.append("question5", reduxState.form.preform.values.question5);
        urlencoded.append("question6", reduxState.form.preform.values.question6);
        urlencoded.append("question7", reduxState.form.preform.values.question7);
        urlencoded.append("program", program);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/evaluation/pre", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EVALUATION_PRE, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const evaluationPost = (program) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_student", reduxState.loginReducer.active_user.id);
        urlencoded.append("postquestion1", reduxState.form.postform.values.postquestion1);
        urlencoded.append("postquestion2", reduxState.form.postform.values.postquestion2);
        urlencoded.append("postquestion3", reduxState.form.postform.values.postquestion3);
        urlencoded.append("postquestion4", reduxState.form.postform.values.postquestion4);
        urlencoded.append("postquestion5", reduxState.form.postform.values.postquestion5);
        urlencoded.append("postquestion6", reduxState.form.postform.values.postquestion6);
        urlencoded.append("postquestion7", reduxState.form.postform.values.postquestion7);
        urlencoded.append("postquestion8", reduxState.form.postform.values.postquestion8);
        urlencoded.append("postquestion9", reduxState.form.postform.values.postquestion9);
        urlencoded.append("program", program);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/evaluation/post", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EVALUATION_POST, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });

    }
};

export const successStory = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var id = "";
        var role = "";
        if (reduxState.loginReducer.active_user.roles[0] == "ROLE_ADMIN" && key != undefined) {
            id = key;
            role = "ROLE_EMBASSADOR"
        }
        else if (reduxState.loginReducer.active_user.roles[0] == "ROLE_ADMIN") {
            id = reduxState.loginReducer.active_user.id
            role = "ROLE_ADMIN"
        }
        else if (reduxState.loginReducer.active_user.roles[0] == "ROLE_EMBASSADOR") {
            id = reduxState.loginReducer.active_user.id
            role = "ROLE_EMBASSADOR"
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", id);
        urlencoded.append("role", role);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/student/successstorylist", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SUCCESS_STORY, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const videoPromotion = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/student/videopromotion", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: VIDEO_PROMOTION, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const availableCourseList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("user_id", reduxState.loginReducer.active_user.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/course/available/list", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: AVAILABLE_COURSE_LIST, payload: json.data });
                    dispatch(dashboardStudent());
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}
export const showUnitStudentList = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("id", key);
        urlencoded.append("student_id", reduxState.loginReducer.active_user.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/unit/show/student/list/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: UNIT_STUDENT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const saveProgress = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("course_id", reduxState.courseReducer.show_course.id);
        urlencoded.append("student_id", reduxState.loginReducer.active_user.id);
        urlencoded.append("principle_id", key);
        urlencoded.append("answer", reduxState.form.principleCompletForm.values.answer);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/progress/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_PROGRESS, payload: json.data });
                    dispatch({ type: RESET_WAITING_PRINCIPLE_LOAD });
                    dispatch({ type: SUCCESSFULL_NEW });
                    dispatch(showUnitListPublished(reduxState.courseReducer.show_course.id));
                    dispatch(showUnitStudentList(reduxState.courseReducer.show_course.id));
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editProgress = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("answer", reduxState.form.principleCompletForm.values.answer);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/edit/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_PROGRESS, payload: json.data });
                    dispatch({ type: RESET_WAITING_PRINCIPLE_LOAD });
                    dispatch({ type: SUCCESSFULL_EDIT })
                    dispatch(showUnitListPublished(reduxState.courseReducer.show_course.id));
                    dispatch(showUnitStudentList(reduxState.courseReducer.show_course.id));
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editProgressCorrections = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("answer", reduxState.form.principleCompletForm.values.answer);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/progress/edit/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_PROGRESS, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT })
                    dispatch(showProgressNextPending(reduxState.courseReducer.show_course.id, reduxState.loginReducer.active_user.id));
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const enrollOnParticipant = (student_id, principle_id) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("course_id", reduxState.courseReducer.show_course.id);
        urlencoded.append("student_id", student_id);
        urlencoded.append("principle_id", principle_id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/progress/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const getStudentCourseList = () => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        var urlencoded = new URLSearchParams();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        urlencoded.append("keyword", reduxState.studentReducer.student_course_list_pagination_params.keyword);
        urlencoded.append("page", reduxState.studentReducer.student_course_list_pagination_params.page);
        urlencoded.append("page_size", reduxState.studentReducer.student_course_list_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.studentReducer.student_course_list_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.studentReducer.student_course_list_pagination_params.order_direction);
        urlencoded.append("course_id", reduxState.courseReducer.show_course.id);

        var requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: urlencoded,
            headers: myHeaders,

        };
        return fetch(reduxState.loginReducer.base_url + "/progress/studentlist/pagination", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: STUDENT_LIST_COURSE, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const searchEnroledList = key => {

    return (dispatch, getState) => {
        const reduxState = getState();
        dispatch({ type: SEARCH_LOADING });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/student/search/enrolled/" + reduxState.courseReducer.show_course.id + "/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: ENDROLLED_COURSE_PARTICIPANT_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const updateParticipantName = (key) => ({ type: UPDATE_PARTICIPANT_NAME, payload: key })

export const newStop = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("course_id", reduxState.courseReducer.show_course.id);
        urlencoded.append("student_id", reduxState.loginReducer.active_user.id);
        urlencoded.append("principle_id", reduxState.courseReducer.show_principle.id);
        urlencoded.append("state", "state.active");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/stop/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const showStudentStop = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const student = reduxState.loginReducer.active_user.id;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + `/stop/show/stop/${key}/${student}`, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    if (json.code !== 500) {
                        dispatch({ type: SHOW_STOP, payload: json.data });
                    } else {
                        dispatch({ type: RESET_SHOW_STOP })
                    }
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const getCourseStudentStopList = (course, student) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + `/stop/${course}/${student}`, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: COURSE_STUDENT_STOP_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const getStudentPaginationList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        urlencoded.append("keyword", reduxState.studentReducer.student_pagination_params.keyword);
        urlencoded.append("page", reduxState.studentReducer.student_pagination_params.page);
        urlencoded.append("page_size", reduxState.studentReducer.student_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.studentReducer.student_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.studentReducer.student_pagination_params.order_direction);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/student/pagination?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PAGINATED_STUDENT_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getStudentGrantmemberPaginationList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        urlencoded.append("keyword", reduxState.studentReducer.student_grantmember_pagination_params.keyword);
        urlencoded.append("page", reduxState.studentReducer.student_grantmember_pagination_params.page);
        urlencoded.append("page_size", reduxState.studentReducer.student_grantmember_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.studentReducer.student_grantmember_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.studentReducer.student_grantmember_pagination_params.order_direction);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/student/grantmember/pagination?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PAGINATED_STUDENT_GRANTMEMBER_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getPotentialSaPaginationList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        urlencoded.append("keyword", reduxState.studentReducer.potentialsa_pagination_params.keyword);
        urlencoded.append("page", reduxState.studentReducer.potentialsa_pagination_params.page);
        urlencoded.append("page_size", reduxState.studentReducer.potentialsa_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.studentReducer.potentialsa_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.studentReducer.potentialsa_pagination_params.order_direction);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/student/potentialsa/pagination?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PAGINATED_POTENTIALSA_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const successStoryPagination = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        urlencoded.append("keyword", reduxState.studentReducer.success_story_pagination_params.keyword);
        urlencoded.append("page", reduxState.studentReducer.success_story_pagination_params.page);
        urlencoded.append("page_size", reduxState.studentReducer.success_story_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.studentReducer.success_story_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.studentReducer.success_story_pagination_params.order_direction);
        urlencoded.append("role", "ROLE_ADMIN");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/student/successstorylist/pagination?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PAGINATED_SUCCESS_STORY_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}
export const editWhatsapp = () => {
    return (dispatch, getState) => {

        const reduxState = getState();
        const key = reduxState.form.studentform.values.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", reduxState.form.studentform.values.username);
        urlencoded.append("language", reduxState.form.studentform.values.language);
        urlencoded.append("firstName", reduxState.form.studentform.values.first_name);
        urlencoded.append("lastName", reduxState.form.studentform.values.last_name);
        urlencoded.append("country", reduxState.form.studentform.values.country);
        urlencoded.append("city", reduxState.form.studentform.values.city);
        urlencoded.append("whatsapp", reduxState.form.studentform.values.whatsapp);
        urlencoded.append("picture", reduxState.form.studentform.values.picture);
        urlencoded.append("idnumber", reduxState.form.studentform.values.idnumber);
        urlencoded.append("areacode", reduxState.form.studentform.values.areacode);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/user/edit/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch(getActiveUser());
                    dispatch(showStudent(reduxState.loginReducer.active_user.id))
                    dispatch({ type: DELETE_SUCCESSFUL });
                }
            })
            .catch(json => {
                dispatch({ type: ERROR_EDIT_STUDENT })
            })
    }
};
export const loadingStudent = () => ({ type: LOADING_STUDENT })

export const getStudentListAmbassadorPagination = () => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();

        urlencoded.append("keyword", reduxState.studentReducer.student_ambassador_pagination_params.keyword);
        urlencoded.append("page", reduxState.studentReducer.student_ambassador_pagination_params.page);
        urlencoded.append("page_size", reduxState.studentReducer.student_ambassador_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.studentReducer.student_ambassador_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.studentReducer.student_ambassador_pagination_params.order_direction);
        urlencoded.append("ambassador_id", reduxState.loginReducer.active_user.id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/student/list/ambassador/pagination", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PAGINATED_STUDENT_AMBASSADOR_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

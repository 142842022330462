import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { setLanguage, translate } from 'react-switch-lang';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// react component for creating dynamic tables
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// @material-ui/icons
import { AccountBalance, ImportContacts, Apps, ViewComfy, Reorder, Message, Face, Dashboard, HowToReg, SupervisorAccount, Collections, LibraryBooks, Person, Stars, Book, School, Cancel, Description, MonetizationOn, Eject, Help, Equalizer, Translate, Announcement, ChromeReaderMode, Payment, ListAlt } from "@material-ui/icons";
import Group2 from "@material-ui/icons/GroupTwoTone";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import { getActiveUser, logoutUser } from "actions/loginActions.jsx";

import en from 'assets/translate/en.json';
import fr from 'assets/translate/fr.json';
import es from 'assets/translate/es.json';
import pt from 'assets/translate/pt.json';
import zh from 'assets/translate/zh.json';
import hi from 'assets/translate/hi.json';

import { GiLaurels } from "assets/img/svg/icons.jsx";
import { GiLaurelCrown } from "assets/img/svg/icons.jsx";
import { FaAward } from "assets/img/svg/icons.jsx";
import { BsAward } from "assets/img/svg/icons.jsx";
import { BiAward } from "assets/img/svg/icons.jsx";
import { CgAwards } from "assets/img/svg/icons.jsx";
import { HiMiniBookmarkSquare } from "assets/img/svg/icons.jsx";

import {
      setTranslations,
} from 'react-switch-lang';
import { Icon } from "@material-ui/core";

setTranslations({ en, fr, es, pt, hi, zh });


class AdminHeaderLinks extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
            };
            this.logoutUser = this.logoutUser.bind(this);
      }

      componentWillMount() {
            //this.props.dispatchGetActiveUser(this.props.history); 
            setLanguage(this.props.active_user.language);
      }

      logoutUser() {
            this.props.dispatchLogoutUser(this.props.history);
      }

      render() {
            const { classes, t, active_user, ambassador_state, ambassador_level } = this.props;
            let links = '';
            let link_menu = "";
            let roles = active_user.roles === undefined ? [] : active_user.roles;
            if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_LANGUAGE_ADMIN")) {
                  link_menu = "link_administrator";
                  links = [
                        <Link to={'/dashboard'} className={classes.dropdownLink}>

                              <Dashboard color="danger" className={classes.icons} /> {t("link_dashboard")}

                        </Link>,
                        <Link to={'/admin/edit/' + active_user.id} className={classes.dropdownLink}>

                              <Person color="danger" className={classes.icons} /> {t("link_user_profile")}

                        </Link>,
                        <Link to={'/admin'} className={classes.dropdownLink}>

                              <Face color="danger" className={classes.icons} /> {t("link_admins")}

                        </Link>,
                        <Link to={'/ambassador'} className={classes.dropdownLink}>

                              <SupervisorAccount color="danger" className={classes.icons} /> {t("link_ambassadors")}

                        </Link>,

                        <Link to={'/student'} className={classes.dropdownLink}>

                              <HowToReg color="danger" className={classes.icons} /> {t("link_participants")}

                        </Link>,
                        <Link to={'/group'} className={classes.dropdownLink}>

                              <Stars color="danger" className={classes.icons} /> {t("link_groups")}

                        </Link>,
                        <Link to={'/grant'} className={classes.dropdownLink}>

                              <MonetizationOn color="danger" className={classes.icons} /> {t("link_grants")}

                        </Link>,
                        <Link to={'/ambassadorstudent'} className={classes.dropdownLink}>

                              <Eject color="danger" className={classes.icons} /> {t("link_ambassador_student")}

                        </Link>,
                        <Link to={'/contract/admin'} className={classes.dropdownLink}>

                              <LibraryBooks color="danger" className={classes.icons} /> {t("link_contracts")}

                        </Link>,
                        <Link to={'/recognition/list'} className={classes.dropdownLink}>

                              <Icon >workspace_premium</Icon>{t("link_recognitions")}

                        </Link>,
                        <Link to={'/certificate'} className={classes.dropdownLink}>

                              <School color="danger" className={classes.icons} /> {t("link_certificates")}

                        </Link>,
                        <Link to={'/student/successstory/list'} className={classes.dropdownLink}>

                              <Book color="danger" className={classes.icons} /> {t("link_success_stories")}

                        </Link>,
                        <Link to={'/report'} className={classes.dropdownLink}>

                              <Equalizer color="danger" className={classes.icons} /> {t("link_reports")}

                        </Link>,
                        <Link to={'/report/ambassadorstatistics'} className={classes.dropdownLink}>

                              <Description color="danger" className={classes.icons} /> {t("link_reports_ambassador")}

                        </Link>,
                        <Link to={'/code'} className={classes.dropdownLink}>

                              <Reorder color="danger" className={classes.icons} /> {t("link_partner_graduates")}

                        </Link>,
                        <Link to={'/translation'} className={classes.dropdownLink}>

                              <Translate color="danger" className={classes.icons} /> {t("link_translation")}

                        </Link>,
                        <Link onClick={this.logoutUser} className={classes.dropdownLink}>

                              <Cancel color="danger" className={classes.icons} /> {t("link_logout")}

                        </Link>,

                  ]
            }
            else if (roles.includes("ROLE_PROJECT_QUALIFIER")) {
                  link_menu = "label_project_qualifier";
                  links = [
                        <Link to={'/dashboard'} className={classes.dropdownLink}>

                              <Dashboard color="danger" className={classes.icons} /> {t("link_dashboard")}

                        </Link>,
                        <Link to={'/admin/edit/' + active_user.id} className={classes.dropdownLink}>

                              <Person color="danger" className={classes.icons} /> {t("link_user_profile")}

                        </Link>,
                        <Link onClick={this.logoutUser} className={classes.dropdownLink}>

                              <Cancel color="danger" className={classes.icons} /> {t("link_logout")}

                        </Link>,
                  ]
            }
            else if (roles.includes("ROLE_EMBASSADOR") && ambassador_state.state === 'state.active') {
                  link_menu = "link_ambassador";
                  links = [
                        <Link to={'/dashboard'} className={classes.dropdownLink}>

                              <Dashboard color="danger" className={classes.icons} /> {t("link_dashboard")}

                        </Link>,
                        <Link to={'/profile'} className={classes.dropdownLink}>

                              <Person color="danger" className={classes.icons} /> {t("link_user_profile")}

                        </Link>,
                        <Link to={active_user.programmbs === undefined ? '/history/new' : '/history/edit/' + active_user.programmbs.id} className={classes.dropdownLink}>

                              <Face color="danger" className={classes.icons} /> {t("link_my_history")}

                        </Link>,
                        <Link to={'/group'} className={classes.dropdownLink}>

                              <Stars color="danger" className={classes.icons} /> {t("link_groups")}

                        </Link>,
                        <Link to={'/grant/ambassador'} className={classes.dropdownLink}>

                              <MonetizationOn color="danger" className={classes.icons} /> {t("link_grants")}

                        </Link>,
                        <Link to={'/student'} className={classes.dropdownLink}>

                              <HowToReg color="danger" className={classes.icons} /> {t("link_participants")}

                        </Link>,
                        <Link to={'/recognition/list'} className={classes.dropdownLink}>

                              <Icon >workspace_premium</Icon>{t("link_recognitions")}

                        </Link>,
                        <Link to={'/certificate'} className={classes.dropdownLink}>

                              <School color="danger" className={classes.icons} /> {t("link_certificates")}

                        </Link>,
                        <Link to={'/nsagraduates/' + active_user.id} className={classes.dropdownLink}>

                              <Group2 color="danger" className={classes.icons} /> {t("link_nsa_graduates")}

                        </Link>,
                        <Link to={'/student/successstory/list'} className={classes.dropdownLink}>

                              <Book color="danger" className={classes.icons} /> {t("link_success_story")}

                        </Link>,
                        <Link to={'/report'} className={classes.dropdownLink}>

                              <Equalizer color="danger" className={classes.icons} /> {t("link_reports")}

                        </Link>,
                        <Link to={'/contract'} className={classes.dropdownLink}>

                              <LibraryBooks color="danger" className={classes.icons} /> {t("link_welcome_package")}

                        </Link>,
                        <Link to={active_user.transfer != undefined ? '/transfer/show/' + active_user.id : 'transfer/new'} className={classes.dropdownLink}>

                              <AccountBalance color="danger" className={classes.icons} /> {t("link_bank_information")}

                        </Link>,
                        <Link to={'/evaluation/mbs/followup/' + active_user.id} className={classes.dropdownLink} target="_blank">

                              <ListAlt color="danger" className={classes.icons} /> {t("link_mbs_follow_up")}

                        </Link>,
                        <Link onClick={this.logoutUser} className={classes.dropdownLink}>

                              <Cancel color="danger" className={classes.icons} /> {t("link_logout")}

                        </Link>,

                  ]
            }

            else if (roles.includes("ROLE_EMBASSADOR") && ambassador_state.state === 'state.inactive') {
                  link_menu = "link_ambassador";
                  links = [
                        <Link to={'/profile'} className={classes.dropdownLink}>

                              <Person color="danger" className={classes.icons} /> {t("link_user_profile")}

                        </Link>,
                        <Link to={'/report'} className={classes.dropdownLink}>

                              <Equalizer color="danger" className={classes.icons} /> {t("link_reports")}

                        </Link>,
                        <Link onClick={this.logoutUser} className={classes.dropdownLink}>

                              <Cancel color="danger" className={classes.icons} /> {t("link_logout")}

                        </Link>,

                  ]
            }

            else if (roles.includes("ROLE_STUDENT_EMBASSADOR")) {
                  link_menu = "link_student";
                  links = [
                        <Link to={'/dashboard/student'} className={classes.dropdownLink}>

                              <Dashboard color="danger" className={classes.icons} /> {t("link_dashboard")}

                        </Link>,
                        <Link to={'/profile'} className={classes.dropdownLink}>

                              <Person color="danger" className={classes.icons} /> {t("link_user_profile")}

                        </Link>,

                        <Link to={'/dashboard/student'} className={classes.dropdownLink}>

                              <LibraryBooks color="danger" className={classes.icons} /> {t("link_program_ambassador")}

                        </Link>,
                        <Link to={'/group'} className={classes.dropdownLink}>

                              <Stars color="danger" className={classes.icons} /> {t("link_groups")}

                        </Link>,
                        <Link to={active_user.programmbs === undefined ? '/history/new' : '/history/edit/' + active_user.programmbs.id} className={classes.dropdownLink}>

                              <Face color="danger" className={classes.icons} /> {t("link_my_history")}

                        </Link>,
                        <Link to={'/student'} className={classes.dropdownLink}>

                              <HowToReg color="danger" className={classes.icons} /> {t("link_participants")}

                        </Link>,
                        <Link to={'/certificate'} className={classes.dropdownLink}>

                              <HowToReg color="danger" className={classes.icons} /> {t("link_certificates")}

                        </Link>,
                        <Link to={'/evaluation/mbs/followup/' + active_user.id} className={classes.dropdownLink} target="_blank">

                              <ListAlt color="danger" className={classes.icons} /> {t("link_mbs_follow_up")}

                        </Link>,
                        <Link onClick={this.logoutUser} className={classes.dropdownLink}>

                              <Cancel color="danger" className={classes.icons} /> {t("link_logout")}

                        </Link>,

                  ]
            }
            else if (roles.includes("ROLE_STUDENT")) {
                  link_menu = "link_student";
                  links = [
                        <Link to={'/course/list'} className={classes.dropdownLink}>

                              <Dashboard color="danger" className={classes.icons} /> {t("link_dashboard")}

                        </Link>,
                        <Link to={'/profile'} className={classes.dropdownLink}>

                              <Person color="danger" className={classes.icons} /> {t("link_user_profile")}

                        </Link>,
                        <Link to={active_user.programmbs === undefined ? '/history/new' : '/history/edit/' + active_user.programmbs.id} className={classes.dropdownLink}>

                              <Face color="danger" className={classes.icons} /> {t("link_my_history")}

                        </Link>,
                        <Link to={'/evaluation/mbs/followup/' + active_user.id} className={classes.dropdownLink} target="_blank">

                              <ListAlt color="danger" className={classes.icons} /> {t("link_mbs_follow_up")}

                        </Link>,
                        <Link onClick={this.logoutUser} className={classes.dropdownLink}>

                              <Cancel color="danger" className={classes.icons} /> {t("link_logout")}

                        </Link>,

                  ]
            }
            else if (roles.includes("ROLE_FACILITATOR") || roles.includes("ROLE_FACILITATOR_MBS")) {
                  link_menu = "link_facilitator";
                  links = [
                        <Link to={'/dashboard'} className={classes.dropdownLink}>

                              <Dashboard color="danger" className={classes.icons} /> {t("link_dashboard")}

                        </Link>,
                        <Link to={'/profile'} className={classes.dropdownLink}>

                              <Person color="danger" className={classes.icons} /> {t("link_user_profile")}

                        </Link>,
                        <Link to={'/group'} className={classes.dropdownLink}>

                              <Stars color="danger" className={classes.icons} /> {t("link_groups")}

                        </Link>,
                        <Link to={'/student'} className={classes.dropdownLink}>

                              <HowToReg color="danger" className={classes.icons} /> {t("link_participants")}

                        </Link>,
                        <Link to={'/certificate'} className={classes.dropdownLink}>

                              <School color="danger" className={classes.icons} /> {t("link_certificates")}

                        </Link>,
                        <Link to={'/report'} className={classes.dropdownLink}>

                              <Equalizer color="danger" className={classes.icons} /> {t("link_reports")}

                        </Link>,
                        <Link onClick={this.logoutUser} className={classes.dropdownLink}>

                              <Cancel color="danger" className={classes.icons} /> {t("link_logout")}

                        </Link>,

                  ]
            }
            else {
                  link_menu = "link_student";
                  links = [];
            }

            let link_support = "https://api.whatsapp.com/send?phone=13852928829";
            if (active_user.language === 'en') {
                  link_support = "https://api.whatsapp.com/send?phone=13852928829";
            }
            else if (active_user.language === 'es') {
                  link_support = "https://api.whatsapp.com/send?phone=593983309589";
            }
            else if (active_user.language === 'pt') {
                  link_support = "https://api.whatsapp.com/send?phone=18014272094";
            }
            else if (active_user.language === 'fr') {
                  link_support = "https://api.whatsapp.com/send?phone=13852928833";
            }
            else if (active_user.language === 'zh') {
                  link_support = "https://api.whatsapp.com/send?phone=13852928829";
            }

            let links_help = [
                  <a href={link_support} target="_blank" className={classes.dropdownLink}>

                        <Message color="danger" className={classes.icons} /> {t("link_live_support")}

                  </a>,
                  <Link to={'/tutorial/user/section'} className={classes.dropdownLink}>

                        <ChromeReaderMode color="danger" className={classes.icons} /> {t("link_tutorial")}

                  </Link>,

            ]

            let links_academy = [
                  <Link to={'/course'} className={classes.dropdownLink}>

                        <Book color="danger" className={classes.icons} /> {t("link_courses")}

                  </Link>,
                  <Link to={'/course/list'} className={classes.dropdownLink}>

                        <ImportContacts color="danger" className={classes.icons} /> {t("link_my_courses")}

                  </Link>,
                  <Link to={'/resource'} className={classes.dropdownLink}>

                        <ViewComfy color="danger" className={classes.icons} /> {t("link_resources")}

                  </Link>,
                  <Link to={'/resource/ambassador/category'} className={classes.dropdownLink}>

                        <Collections color="danger" className={classes.icons} /> {t("link_my_resources")}

                  </Link>,
                  <Link to={'/group/introduction'} className={classes.dropdownLink}>

                        <Announcement color="danger" className={classes.icons} /> {t("link_introduction")}

                  </Link>,
                  <Link to={'/tutorial'} className={classes.dropdownLink}>

                        <ChromeReaderMode color="danger" className={classes.icons} /> {t("link_tutorial")}

                  </Link>,
                  <Link to={'/payment'} className={classes.dropdownLink}>

                        <Payment className={classes.icons} /> {t("link_payment")}

                  </Link>
            ]

            let links_available_courses = [
                  <Link to={'/course/list'} className={classes.dropdownLink}>

                        <ImportContacts color="danger" className={classes.icons} /> {t("link_my_courses")}

                  </Link>,
                  <Link to={'/resource/ambassador/category'} className={classes.dropdownLink}>

                        <Collections color="danger" className={classes.icons} /> {t("link_my_resources")}

                  </Link>
            ]

            const ambassadorProfileIcon = {
                  double_platinum: <GiLaurelCrown fill="white" className={classes.icons} />,
                  platinum: <GiLaurels fill="white" className={classes.icons} />,
                  gold: <FaAward fill="white" className={classes.icons} />,
                  silver: <BsAward fill="white" className={classes.icons} />,
                  bronze: <BiAward fill="white" className={classes.icons} />,
                  mentor: <CgAwards fill="white" className={classes.icons} />,
                  sa: <HiMiniBookmarkSquare fill="white" className={classes.icons} />,
            }

            return (
                  <List className={classes.list}>
                        <ListItem className={classes.listItem}>
                              {roles.includes("ROLE_ADMIN") || roles.includes("ROLE_LANGUAGE_ADMIN") || roles.includes("ROLE_PROJECT_QUALIFIER") ?
                                    <Link to={'/admin/edit/' + active_user.id} className={classes.navLink}>
                                    </Link>
                                    : roles.includes("ROLE_EMBASSADOR") && ambassador_level !== "" ?
                                          <Link to={'/profile'} className={classes.navLink}>
                                                {ambassadorProfileIcon[ambassador_level]}
                                                {active_user.first_name + " " + active_user.last_name}
                                          </Link>
                                          :
                                          <Link to={'/profile'} className={classes.navLink}>
                                                <Face color="danger" className={classes.icons} /> {active_user.first_name + " " + active_user.last_name}
                                          </Link>
                              }

                        </ListItem>
                        <ListItem className={classes.listItem}>
                              <CustomDropdown
                                    noLiPadding
                                    buttonText={t(link_menu)}
                                    buttonProps={{
                                          className: classes.navLink,
                                          color: "transparent"
                                    }}
                                    hoverColor="info"
                                    buttonIcon={Apps}
                                    dropdownList={links}
                              />
                        </ListItem>
                        {roles.includes("ROLE_ADMIN") || roles.includes("ROLE_LANGUAGE_ADMIN") ?
                              <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                          noLiPadding
                                          buttonText={t("link_academy")}
                                          buttonProps={{
                                                className: classes.navLink,
                                                color: "transparent"
                                          }}
                                          hoverColor="info"
                                          buttonIcon={School}
                                          dropdownList={links_academy}
                                    />
                              </ListItem>
                              : ""
                        }
                        {roles.some(role => ["ROLE_STUDENT", "ROLE_STUDENT_EMBASSADOR", "ROLE_EMBASSADOR", "ROLE_FACILITATOR", "ROLE_FACILITATOR_MBS"].includes(role)) ?
                              <ListItem className={classes.listItem}>
                                    <CustomDropdown
                                          noLiPadding
                                          buttonText={t("link_academy")}
                                          buttonProps={{
                                                className: classes.navLink,
                                                color: "transparent"
                                          }}
                                          hoverColor="info"
                                          buttonIcon={School}
                                          dropdownList={links_available_courses}
                                    />
                              </ListItem>
                              : ""
                        }
                        <ListItem className={classes.listItem}>

                              <CustomDropdown
                                    noLiPadding
                                    buttonText={t('link_help')}
                                    buttonProps={{
                                          className: classes.navLink,
                                          color: "transparent"
                                    }}
                                    hoverColor="info"
                                    buttonIcon={Help}
                                    dropdownList={links_help}
                              />
                        </ListItem>
                        <ListItem className={classes.listItem}>
                              <Link onClick={this.logoutUser} className={classes.dropdownLink}>
                                    <Cancel color="danger" className={classes.icons} /> {t("link_logout")}
                              </Link>
                        </ListItem>

                  </List>
            );
      }
}

const mapStateToProps = state => ({
      active_user: state.loginReducer.active_user,
      ambassador_state: state.ambassadorReducer.ambassador_state,
      ambassador_level: state.grantReducer.ambassador_level,
});

const mapDispatchToPropsActions = dispatch => ({
      dispatchGetActiveUser: redirect => dispatch(getActiveUser(redirect)),
      dispatchLogoutUser: redirect => dispatch(logoutUser(redirect))
});

const LoginFormComponent = translate(withStyles(headerLinksStyle)(AdminHeaderLinks));
export default withRouter(connect(mapStateToProps, mapDispatchToPropsActions)(LoginFormComponent));

